import { ElLoading, ElMessage } from 'element-plus';
import 'element-plus/es/components/loading/style/css'
import 'element-plus/es/components/message/style/css'

export default {
  name: "TaskAdd",
  data() {
    return {
      edit: false,
      misfirePolicyItems: [{
        value: '0',
        label: '默认'
      }, {
        value: '1',
        label: '立即执行'
      }, {
        value: '2',
        label: '执行一次'
      }, {
        value: '3',
        label: '放弃执行'
      }],
      formModel: {
        className: null,
        name: null,
        cron: null,
        taskDesc: null,
        taskGroup: '',
        misfirePolicy: "0",
        recordLog: "N"
      },
      formRules: {
        className: [{
          required: true,
          message: '请输入任务Class',
          trigger: 'blur'
        }, {
          max: 128,
          message: '任务Class长度不能超过128',
          trigger: 'blur'
        }],
        name: [{
          required: true,
          message: '请输入任务名称',
          trigger: 'blur'
        }, {
          max: 128,
          message: '任务名称长度不能超过128',
          trigger: 'blur'
        }],
        cron: [{
          required: true,
          message: '请输入cron表达式,例如: 0 0/30 * * * ?',
          trigger: 'blur'
        }, {
          max: 64,
          message: 'cron表达式长度不能超过64',
          trigger: 'blur'
        }],
        taskGroup: [{
          required: true,
          message: '请输入任务组名称',
          trigger: 'blur'
        }, {
          max: 32,
          message: '任务组名称长度不能超过32',
          trigger: 'blur'
        }],
        misfirePolicy: [{
          required: true,
          message: '请选择计划执行错误策略',
          trigger: 'blur'
        }]
      }
    };
  },
  methods: {
    submitForm() {
      this.$refs.formRef.validate(valid => {
        if (valid) {
          const loading = ElLoading.service();
          const param = Object.assign({}, this.formModel);
          const callBack = res => {
            if (res.code === 200) {
              ElMessage({
                message: res.message,
                type: 'success'
              });
              this.$router.back();
            } else {
              ElMessage.error(res.message);
            }
          };
          if (this.edit) {
            this.$api.business.task.update(param).then(callBack).finally(() => {
              loading.close();
            });
          } else {
            this.$api.business.task.create(param).then(callBack).finally(() => {
              loading.close();
            });
          }
        }
      });
    }
  },
  beforeMount() {
    if (this.$route.query.className) {
      this.edit = true;
      const loading = ElLoading.service();
      this.$api.business.task.detail(this.$route.query.className).then(res => {
        if (res.code === 200) {
          this.formModel = Object.assign(this.formModel, res.data);
        } else if (res.code === 121301) {
          this.$router.replace({
            name: '404'
          });
        } else {
          ElMessage.error(res.message);
          this.$router.back();
        }
      }).finally(() => {
        loading.close();
      });
    }
  }
};